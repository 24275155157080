import ALL_GUIDES from '@/graphql/guides/query/getAllGuidesAutocomplete.gql'

const guides = ref([])
const key = ref(0)
const firstTime = ref(true)

export async function guidesAutocomplete(params: any) {
  const apolloQuery = ALL_GUIDES
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: 'FIRST_NAME',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchGuidesCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await guidesAutocomplete({ search: queryString })
    cb(results?.getAllGuides?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchGuidesCb')
  }
}

export function getSearchGuides(entityGuide: any, entityGuides: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchGuides('', entityGuide, entityGuides)
  }
  return guides?.value ?? []
}

export async function searchGuides(queryString: any, entityGuide: any, entityGuides: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await guidesAutocomplete({ search: queryString })
    const remoteGuides = results?.getAllGuides?.data?.map((i: any) => {
      return {
        label: `${i.first_name} ${i.last_name}`,
        value: i.uuid
      }
    }) ?? []
    const guidesToAdd: { label: any; value: any }[] = []
    entityGuides?.forEach((j: any) => {
      if (!remoteGuides.find((k: any) => k.value === j.uuid)) {
        guidesToAdd.push({
          label: `${j.first_name} ${j.last_name}`,
          value: j.uuid
        })
      }
    })
    if (entityGuide && !remoteGuides.find((k: any) => k.value === entityGuide.uuid)) {
      guidesToAdd.push({
        label: `${entityGuide.first_name} ${entityGuide.last_name}`,
        value: entityGuide.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    guides.value = [...remoteGuides, ...guidesToAdd]

    firstTime.value = false
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchGuides')
  }
}

export function getGuideKey () {
  return key.value
}

export function initialGuides () {
  guides.value = []
  key.value = 0
  firstTime.value = true
}